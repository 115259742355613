import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ImSpinner3 } from 'react-icons/im';

const ActivityIndicator = ({ size = 24, color, smooth }) => (
  <Spinner size={size} color={color} smooth={smooth}>
    <ImSpinner3 />
  </Spinner>
);

const rotate360 = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  display: inline-flex;
  animation: ${rotate360} 1.4s infinite linear;
  opacity: ${props => (props.smooth ? 1 : 0.7)};
  justify-self: center;
  align-self: center;

  svg {
    font-size: ${props => (props.size ? `${props.size}px` : '40px')};
    color: ${props =>
      props.color === 'secondary'
        ? props.theme.textColors.secondary
        : props.color || 'black'};
    margin-right: 0 !important;
  }

  ${({ smooth, color, size, theme }) =>
    smooth &&
    `
    position: relative;
    background: linear-gradient(to right, ${color ||
      theme.colors.tertiary} 10%, rgba(247,248,247, 0) 42%);
    border-radius: 50%;
    transform: translateZ(0);
    animation-duration: 0.9s;

    width: ${size || 40}px;
    height: ${size || 40}px;
    
    &:before {
      width: 50%;
      height: 50%;
      background: ${theme.colors.primaryLight};
      border-radius: 100% 0 0 0;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
    }

    &:after {
      background: ${theme.backgroundColors.primary};
      width: 65%;
      height: 65%;
      border-radius: 50%;
      content: '';
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    svg {
      display: none;
    }
  `}
`;

export default ActivityIndicator;
